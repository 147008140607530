const red = {
    '010': '#fff1f0',
    '020': '#ffccc7',
    '030': '#ffa39e',
    '040': '#ff7875',
    '050': '#ff4d4f',
    '060': '#f5222d',
    '070': '#cf1322',
    '080': '#a8071a',
    '090': '#820014',
    '100': '#5c0011',
}

const blue = {
    '010': '#e6f4ff',
    '020': '#bae0ff',
    '030': '#91caff',
    '040': '#69b1ff',
    '050': '#4096ff',
    '060': '#1677ff',
    '070': '#0958d9',
    '080': '#003eb3',
    '090': '#002c8c',
    '100': '#001d66'
}

const orange = {
    '010': '#fff7e6',
    '020': '#ffe7ba',
    '030': '#ffd591',
    '040': '#ffc069',
    '050': '#ffa940',
    '060': '#fa8c16',
    '070': '#d46b08',
    '080': '#ad4e00',
    '090': '#873800',
    '100': '#612500',
}

const green = {
    '010': '#f6ffed',
    '020': '#d9f7be',
    '030': '#b7eb8f',
    '040': '#95de64',
    '050': '#73d13d',
    '060': '#52c41a',
    '070': '#389e0d',
    '080': '#237804',
    '090': '#135200',
    '100': '#092b00',
}

const gray = {
    '010': '#ffffff',
    '020': '#fafafa',
    '030': '#f5f5f5',
    '040': '#f0f0f0',
    '050': '#d9d9d9',
    '060': '#bfbfbf',
    '070': '#8c8c8c',
    '080': '#595959',
    '090': '#434343',
    '100': '#262626',
    '110': '#1f1f1f',
    '120': '#141414',
    '130': '#000000',
}

const slate = {
    '010': '#F8FAFCFF',
    '020': '#F1F5F9FF',
    '030': 'rgb(226, 232, 240)',
    '040': 'rgb(203, 213, 225)',
    '050': 'rgb(148, 163, 184)',
    '060': 'rgb(100, 116, 139)',
    '070': 'rgb(71, 85, 105)',
    '080': 'rgb(51, 65, 85)',
    '090': 'rgb(30, 41, 59)',
    '100': 'rgb(15, 23, 42)',
}

export const colors = {
    main: '#000',
    // main: blue["070"],
    background: '#F1F3F8',
    black: '#000000',
    white: '#FFFFFF',
    gray,
    red,
    blue,
    orange,
    green,
    slate,
};

import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {userTypes} from "../types/user";
import {useCustomSearchParams} from "../utils/useCustomSearchParams";

export const useLogout = () => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/user/logout`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        }
    );
}

export const useLogin = () => {
    const api = useCallback((data: Pick<userTypes, 'id' | 'password'>) => {
        return axios.post(`/admin/api/v1/user/login`, data);
    }, []);

    return useMutation(
        async (data: Pick<userTypes, 'id' | 'password'>) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useLoginCheck = () => {
    const api = useCallback(() => {
        return axios.post('/admin/api/v1/user/login/check');
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useUpdateUser = () => {
    const api = useCallback((data: userTypes) => {
        return axios.put('/admin/api/v1/user', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertUser = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/admin/api/v1/user', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteUser = () => {
    const api = useCallback(({seq}: Pick<userTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/user/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<userTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useUpdatePassword = () => {
    const api = useCallback(({password}: { password: string }) => {
        return axios.put('/vendor/api/v1/user/password', {password});
    }, []);

    return useMutation(
        async ({password}: { password : string }) => {
            const response = await api({password});
            return response.data;
        },
    );
}

export const useFindUser = ({seq}: userTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/user/${seq}`);
    }, []);

    return useQuery(
        ['user'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindUsers = () => {
    const {page, search, category} = useCustomSearchParams().get();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/user/list`, {page, search, category});
    }, [page, search, category]);

    return useQuery(
        ['users', {page, search, category}],
        async () => {
            const response = await api();
            return response.data;
        }, {
            keepPreviousData: true
        }
    );
}

export const useSendEmailCode = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/auth/send', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUserFindPw = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('/vendor/api/v1/user/find/pw', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

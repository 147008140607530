import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {layoutTypes} from "../types/layout";
import {useCallback} from "react";

export const layoutState = atom<layoutTypes>({
    key: 'layout',
    default: {
        fold: false,
    }
});

export const useRecoilGetFoldLayout = () => {
    return useRecoilValue(layoutState);
};

export const useRecoilFoldLayout = () => {
    const setFold = useSetRecoilState(layoutState);

    return useCallback(({fold}: layoutTypes) => {
        setFold({
            fold: !fold,
        });
    }, []);
};

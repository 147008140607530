import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {lazy, Suspense, useEffect} from "react";
import {useLoginCheck} from "../../api/user";
import {useRecoilSetUser} from "../../recoil/user";
import LayoutLeftTemplate from "../../components/templates/layout/left";

const Login = lazy(() => import('../login').then((module) => ({default: module.Login})));
const Privacy = lazy(() => import('../privacy').then((module) => ({default: module.Privacy})));

const AddUser = lazy(() => import('../user/add').then((module) => ({default: module.AddUser})));
const User = lazy(() => import('../user').then((module) => ({default: module.User})));
const EditUser = lazy(() => import('../user/edit').then((module) => ({default: module.EditUser})));
const DetailUser = lazy(() => import('../user/detail').then((module) => ({default: module.DetailUser})));

const AddActor = lazy(() => import('../actor/add').then((module) => ({default: module.AddActor})));
const Actor = lazy(() => import('../actor').then((module) => ({default: module.Actor})));
const EditActor = lazy(() => import('../actor/edit').then((module) => ({default: module.EditActor})));
const DetailActor = lazy(() => import('../actor/detail').then((module) => ({default: module.DetailActor})));

const AddVideo = lazy(() => import('../video/add').then((module) => ({default: module.AddVideo})));
const Video = lazy(() => import('../video').then((module) => ({default: module.Video})));
const EditVideo = lazy(() => import('../video/edit').then((module) => ({default: module.EditVideo})));
const DetailVideo = lazy(() => import('../video/detail').then((module) => ({default: module.DetailVideo})));

const AddBoard = lazy(() => import('../board/add').then((module) => ({default: module.AddBoard})));
const Board = lazy(() => import('../board').then((module) => ({default: module.Board})));
const EditBoard = lazy(() => import('../board/edit').then((module) => ({default: module.EditBoard})));
const DetailBoard = lazy(() => import('../board/detail').then((module) => ({default: module.DetailBoard})));

const AddCategory = lazy(() => import('../category/add').then((module) => ({default: module.AddCategory})));
const Category = lazy(() => import('../category').then((module) => ({default: module.Category})));
const EditCategory = lazy(() => import('../category/edit').then((module) => ({default: module.EditCategory})));
const DetailCategory = lazy(() => import('../category/detail').then((module) => ({default: module.DetailCategory})));

const AddBanner = lazy(() => import('../banner/add').then((module) => ({default: module.AddBanner})));
const Banner = lazy(() => import('../banner').then((module) => ({default: module.Banner})));
const EditBanner = lazy(() => import('../banner/edit').then((module) => ({default: module.EditBanner})));
const DetailBanner = lazy(() => import('../banner/detail').then((module) => ({default: module.DetailBanner})));

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const setUser = useRecoilSetUser();
    const {mutate} = useLoginCheck();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/login') return;
        if (location.pathname === '/') navigate('/user');

        mutate(undefined, {
            onSuccess: async ({data}) => {
                if (!data.seq) if (location.pathname !== '/login') navigate('/login');
                setUser({seq: data.seq, id: data.id, email: data.email, name: data.name, role: data.role});
            }
        });
    }, [location.pathname]);

    return (
        <Suspense fallback={location.pathname === '/login' ? <></> : <LayoutLeftTemplate children={<></>} />}>
            <Routes>
                <Route path="/login" element={<Login/>}/>

                <Route path="/user" element={<User/>}/>
                <Route path="/user/:seq" element={<DetailUser/>}/>
                <Route path="/user/add" element={<AddUser/>}/>
                <Route path="/user/edit/:seq" element={<EditUser/>}/>

                <Route path="/actor" element={<Actor/>}/>
                <Route path="/actor/:seq" element={<DetailActor/>}/>
                <Route path="/actor/add" element={<AddActor/>}/>
                <Route path="/actor/edit/:seq" element={<EditActor/>}/>

                <Route path="/video" element={<Video/>}/>
                <Route path="/video/:seq" element={<DetailVideo/>}/>
                <Route path="/video/add" element={<AddVideo/>}/>
                <Route path="/video/edit/:seq" element={<EditVideo/>}/>

                <Route path="/board" element={<Board/>}/>
                <Route path="/board/:seq" element={<DetailBoard/>}/>
                <Route path="/board/add" element={<AddBoard/>}/>
                <Route path="/board/edit/:seq" element={<EditBoard/>}/>

                <Route path="/category" element={<Category/>}/>
                <Route path="/category/:seq" element={<DetailCategory/>}/>
                <Route path="/category/add" element={<AddCategory/>}/>
                <Route path="/category/edit/:seq" element={<EditCategory/>}/>

                <Route path="/banner" element={<Banner/>}/>
                <Route path="/banner/:seq" element={<DetailBanner/>}/>
                <Route path="/banner/add" element={<AddBanner/>}/>
                <Route path="/banner/edit/:seq" element={<EditBanner/>}/>

                <Route path="/privacy" element={<Privacy/>}/>
            </Routes>
        </Suspense>
    );
}

import logo from "../../../assets/images/logo.png";
import Icon from "../../atoms/icon";
import styled from "styled-components";
import {useRecoilGetUser} from "../../../recoil/user";
import {useRecoilGetFoldLayout} from "../../../recoil/layout";
import {useNavigate, useLocation} from "react-router-dom";
import {ReactNode, useCallback} from "react";
import {StaticCategory} from "../../../constants/staticCategory";
import {useLogout} from "../../../api/user";
import {colors} from "../../../styles/colors";

const LayoutLeftTemplate = ({children}: { children: ReactNode }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {name, role} = useRecoilGetUser();
    const {fold} = useRecoilGetFoldLayout();
    const {mutate} = useLogout();

    const onLogout = useCallback(() => {
        mutate(undefined, {
            onSuccess: () => {
                navigate('/login');
            }
        });
    }, []);

    return (
        <LayoutWrap>
            <LayoutMainWrap>
                <LayoutSideBarContainer>
                    <LayoutSideBarWrap $fold={fold}>
                        <LayoutSideBarTopWrap>
                            <LogoWrap onClick={() => navigate('/user')}>
                                <Logo src={logo} alt={'logo'}/>
                            </LogoWrap>

                            <IconLabelsWrap>
                                {
                                    StaticCategory.map((i, index) =>
                                        i.role.indexOf(role || '') !== -1 &&
                                        (
                                            <IconLabelWrap onClick={() => navigate(i.route)} key={index} index={index}
                                                           className={location.pathname.indexOf(i.route) !== -1 ? 'active' : ''}>
                                                <div dangerouslySetInnerHTML={{__html: i.icon}}/>
                                                <span> {i.name} </span>
                                            </IconLabelWrap>
                                        ))
                                }
                            </IconLabelsWrap>

                        </LayoutSideBarTopWrap>

                        <LayoutSideBarBottomWrap>
                            <UserWrap>
                                <AvatarWrap>
                                    <Icon width={'50px'} height={'50px'}
                                          background={`url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 53 53' style='enable-background:new 0 0 53 53;' xml:space='preserve'%3e%3cpath style='fill:%23E7ECED;' d='M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53 c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322 c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546 c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126 c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24 C20.296,39.899,19.65,40.986,18.613,41.552z'/%3e%3cg%3e%3cpath style='fill:%23556080;' d='M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76 c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633 c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977 s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53 c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233 c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z'/%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3c/svg%3e")`}/>
                                </AvatarWrap>
                                <UserInfoWrap>
                                    <span>{name}</span>
                                    <span>{role}</span>
                                </UserInfoWrap>
                            </UserWrap>

                            <IconButtonWrap onClick={onLogout}>
                                <Icon width={'24px'} height={'24px'}
                                      background={`url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' strokeidth='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-log-out'%3e%3cpath d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'/%3e%3cpolyline points='16 17 21 12 16 7'/%3e%3cline x1='21' y1='12' x2='9' y2='12'/%3e%3c/svg%3e")`}/>
                                <span>로그아웃</span>
                            </IconButtonWrap>
                        </LayoutSideBarBottomWrap>
                    </LayoutSideBarWrap>
                </LayoutSideBarContainer>

                <LayoutContentsWrap>
                    {children}
                </LayoutContentsWrap>
            </LayoutMainWrap>
        </LayoutWrap>
    )
};

const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
`

const LayoutSideBarWrap = styled.div<{ $fold: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  visibility: ${({$fold}) => $fold ? 'hidden' : 'visible'};
  min-width: ${({$fold}) => $fold ? '0' : '250px'};
  width: ${({$fold}) => $fold ? '0' : '250px'};
  opacity: ${({$fold}) => $fold ? '0' : '1'};
  transition: visibility 0s ease-in-out 0s, min-width 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
`

const LayoutSideBarContainer = styled.div`
  position: relative;
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 40px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
`

const Logo = styled.img`
  width: 100%;
  height: 60px;
  object-fit: contain;
`;

const UserWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding: 20px;
  border-top: 1px solid #eee;
`

const UserInfoWrap = styled.div`
  display: flex;
  flex-direction: column;

  & span:first-child {
    font-weight: 600;
  }

  & span:last-child {
    color: #bbb;
  }
`

const AvatarWrap = styled.div`
  width: 50px;
`

const LayoutMainWrap = styled.div`
  display: flex;
  flex: 1;

  @keyframes slideX {
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes slideY {
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const LayoutSideBarProviderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  gap: 20px;
  font-weight: 500;

  & > img {
    max-width: 100px;
  }

  & > span {
    font-size: 11px;
    color: rgba(34, 34, 34, .5);
    line-height: 18px;
  }
`

const LayoutSideBarTopWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100%;
  min-width: 250px;
  gap: 20px;
  padding: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const LayoutSideBarBottomWrap = styled.div`
  padding: 20px;
`;

const LayoutContentsWrap = styled.div`
  display: flex;
  flex: 1;
  background-color: rgb(241, 243, 248);
  box-shadow: inset rgb(0 0 0 / 15%) 0 0 6px 0;
`

const IconLabelsWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const IconButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 20px;

  background-color: #bbb;
  color: #fff;

  &:hover {
    background-color: ${colors.main};
    transition: background-color 0.3s;
  }
`

const IconLabelWrap = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 20px;

  opacity: 0;
  transform: translateX(50px);
  animation: 0.4s slideX ease-in-out 0.3s;
  animation-fill-mode: forwards;
  animation-delay: calc(${({index}) => index} * 0.1s);

  &.active {
    background-color: ${colors.main};
    color: #fff;
  }

  & svg {
    color: #000;
  }

  &.active svg {
    color: #fff;
  }

  & > div {
    display: flex;
  }
`

export default LayoutLeftTemplate;
